import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import './ContactInfoSection.css';

const ContactInfoSection = () => {
    return (
        <section className="contact-info-section" aria-labelledby="contact-info-title">
            <h2 id="contact-info-title">Informações de Contato</h2>
            <div className="info-container">
                <div className="info-item">
                    <FaPhone className="info-icon" aria-hidden="true" />
                    <h3>Telefone</h3>
                    <p>(11) 4002-8922</p>
                </div>
                <div className="info-item">
                    <FaEnvelope className="info-icon" aria-hidden="true" />
                    <h3>E-mail</h3>
                    <p>suporte@prontuariofacil.com.br</p>
                </div>
                <div className="info-item">
                    <FaMapMarkerAlt className="info-icon" aria-hidden="true" />
                    <h3>Endereço</h3>
                    <p>Rua dos Bobos, nº 0, Cidade Exemplo, SP</p>
                </div>
            </div>
        </section>
    );
};

export default ContactInfoSection;
