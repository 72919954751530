import React from 'react';
import './ContactHeroSection.css';

const ContactHeroSection = () => {
  return (
    <section className="contact-hero-section" aria-labelledby="contact-hero-title" aria-describedby="contact-hero-description">
      <h1 id="contact-hero-title">Entre em Contato</h1>
      <p id="contact-hero-description">Tem perguntas? Precisa de mais informações? Estamos aqui para ajudar!</p>
    </section>
  );
};

export default ContactHeroSection;
