import React, { useState } from 'react';
import './DemoRequestSection.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DemoRequestSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const sanityConfig = {
        projectId: '6v4gz3ce',
        dataset: 'production',
        token: 'skkA7jZCKAms5ujN3q6M3kvZbHonBC8CHZ45cNGNeRQb4QL1AXOW9d8yjm97WE1Bnu8r32bOgCMjCRBlAqYFl6y9tfnTwKwoA8pfuWjkGNexSeSnF2wNXRgqSI6jzzvDVZ6rxw080IYr4XSS5XiolsLehSUIeT929W6rOnbT0KHUuht0lBUu',
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentTimestamp = new Date().toISOString();
        try {
            await axios.post(
                `https://${sanityConfig.projectId}.api.sanity.io/v2021-06-07/data/mutate/${sanityConfig.dataset}`,
                {
                    mutations: [
                        {
                            create: {
                                _type: 'contact',
                                ...formData,
                                createdAt: currentTimestamp, // Adiciona o timestamp atual
                            },
                        },
                    ],
                },
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${sanityConfig.token}`,
                    },
                }
            );
            toast.success('Solicitação enviada com sucesso!');
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            console.error('Erro ao enviar solicitação:', error);
            toast.error('Erro ao enviar solicitação. Tente novamente.');
        }
    };

    return (
        <section className="demo-request-section" aria-labelledby="demo-request-heading" aria-describedby="demo-request-description">
            <ToastContainer />
            <div className="container-demo">
                <h2 id="demo-request-heading">Comece Agora Gratuitamente</h2>
                <p id="demo-request-description">Preencha o formulário abaixo e um de nossos especialistas entrará em contato para agendar uma demonstração do Prontuário Fácil.</p>
                <form className="demo-request-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="demo-name">Seu Nome</label>
                        <input
                            type="text"
                            name="name"
                            id="demo-name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Seu Nome"
                            required
                            aria-required="true"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="demo-email">Seu Email</label>
                        <input
                            type="email"
                            name="email"
                            id="demo-email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Seu Email"
                            required
                            aria-required="true"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="demo-message">Deixe uma mensagem (opcional)</label>
                        <textarea
                            name="message"
                            id="demo-message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Deixe uma mensagem (opcional)"
                        />
                    </div>
                    <button type="submit" className="submit-button" id="botao-cadastrar-conta">Enviar Solicitação</button>
                </form>
            </div>
        </section>
    );
};

export default DemoRequestSection;
