import React from 'react';
import './contato.css';
import ContactHeroSection from '../components/ContactHeroSection/ContactHeroSection';
import ContactInfoSection from '../components/ContactInfoSection/ContactInfoSection';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import DemoRequestSection from '../components/DemoRequestSection/DemoRequestSection';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';

const Contact = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/contato', label: 'Contato' }
  ];

  return (
    <Layout>
      <div className="contact-container">
        <Seo
          title="Entre em contato - Prontuário Fácil"
          description="Fale conosco! Tire suas dúvidas, solicite um orçamento ou agende uma demonstração do software médico Prontuário Fácil."
          keywords="contato, prontuário eletrônico, software médico, suporte, dúvidas, orçamento, demonstração"
        />
        <Breadcrumbs crumbs={crumbs} />
        <main className="content">
          <ContactHeroSection />
          <DemoRequestSection />
          <ContactInfoSection />
        </main>
      </div>
    </Layout>
  );
};

export default Contact;
